.ReactInputVerificationCode__container {
  width: 100% !important;
  padding: 0 20px;
}

.ReactInputVerificationCode__item {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
}

.css-1ohhda4 {
  width: 40px !important;
  height: 40px !important;
}
